
import { defineComponent } from 'vue'
import Topbar from '@/components/Topbar/Topbar.vue'
import SideNav from '@/components/SideNav/SideNav.vue'
import Ipv4RangeBlock from '@/components/Range/Ipv4RangeBlock.vue'
import Ipv6RangeBlock from '@/components/Range/Ipv6RangeBlock.vue'
import '@progress/kendo-ui/js/kendo.dialog'
import { Dialog } from '@progress/kendo-vue-dialogs'
import useToggle from '@/composable/useToggle'
import NewIpv4RangeForm from '@/components/Form/RangeSettingView/NewIpv4RangeForm.vue'

export default defineComponent({
  components: {
    Topbar,
    SideNav,
    Ipv4RangeBlock,
    Ipv6RangeBlock,
    Dialog,
    NewIpv4RangeForm
  },
  setup() {
    const { show: showNewIpv4Range, toggle: toggleNewIpv4Range } = useToggle()

    return {
      showNewIpv4Range,
      toggleNewIpv4Range
    }
  }
})
