<template>
  <div id="view-setting">
    <!-- 上方：標題 -->
    <Topbar :backTo="{ name: 'Network' }">業務課 網段設定</Topbar>
    <div class="content-container">
      <!-- 左側：次導覽列 -->
      <SideNav>
        <router-link
          class="side-nav-link router-link-active"
          :to="{ name: 'RangeSetting', params: $route.params.id }"
          >管理範圍設定</router-link
        >
        <router-link
          class="side-nav-link"
          :to="{ name: 'GeneralSetting', params: $route.params.id }"
          >屬性設定</router-link
        >
        <router-link
          class="side-nav-link"
          :to="{ name: 'DHCPDNSSetting', params: $route.params.id }"
          >DHCP DNS 設定</router-link
        >
      </SideNav>

      <div class="setting-container px-4">
        <!-- IPv4 管理範圍 -->
        <div class="title-row" style="marginLeft: 10px; marginRight: 10px;">
          <h3>IPv4 管理範圍</h3>
          <div>
            <span class="text-danger mr-3">
              <SvgIcon icon="warning" /> 須完成 DHCP DNS 設定
              <a
                href="#"
                class="text-danger"
                style="text-decoration: underline;"
                >前往</a
              ></span
            >

            <button
              class="btn form-btn-outline mr-2 px-3"
              @click="toggleNewIpv4Range"
            >
              <SvgIcon icon="plus" />
              新增 IPv4 管理範圍
            </button>
          </div>
        </div>
        <div class="range-block-container">
          <Ipv4RangeBlock />
          <Ipv4RangeBlock />
        </div>
        <!-- IPv6 管理範圍 -->
        <div
          class="title-row mt-4"
          style="marginLeft: 10px; marginRight: 10px;"
        >
          <h3>IPv6 管理範圍</h3>
          <button class="btn form-btn-outline mr-2 px-3" @click="toggleForm">
            <SvgIcon icon="plus" />
            新增 IPv6 管理範圍
          </button>
        </div>
        <div class="range-block-container">
          <Ipv6RangeBlock />
          <Ipv6RangeBlock />
        </div>
      </div>
    </div>
  </div>
  <!-- 隱藏：Dialog 容器 -->
  <div class="dialog-container">
    <Dialog
      v-if="showNewIpv4Range"
      :title="'新增 IPv4 管理範圍'"
      :width="930"
      :height="650"
      @close="toggleNewIpv4Range"
    >
      <NewIpv4RangeForm @close="toggleNewIpv4Range" />
    </Dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Topbar from '@/components/Topbar/Topbar.vue'
import SideNav from '@/components/SideNav/SideNav.vue'
import Ipv4RangeBlock from '@/components/Range/Ipv4RangeBlock.vue'
import Ipv6RangeBlock from '@/components/Range/Ipv6RangeBlock.vue'
import '@progress/kendo-ui/js/kendo.dialog'
import { Dialog } from '@progress/kendo-vue-dialogs'
import useToggle from '@/composable/useToggle'
import NewIpv4RangeForm from '@/components/Form/RangeSettingView/NewIpv4RangeForm.vue'

export default defineComponent({
  components: {
    Topbar,
    SideNav,
    Ipv4RangeBlock,
    Ipv6RangeBlock,
    Dialog,
    NewIpv4RangeForm
  },
  setup() {
    const { show: showNewIpv4Range, toggle: toggleNewIpv4Range } = useToggle()

    return {
      showNewIpv4Range,
      toggleNewIpv4Range
    }
  }
})
</script>

<style scoped lang="scss"></style>
