<template>
  <div class="range-block">
    <div class="range-control">
      <SvgIcon icon="edit" /><SvgIcon icon="garbage" />
    </div>
    <div class="range-title">
      <SvgIcon icon="range" />
      192.168.61.x
    </div>
    <div class="range-content">
      <div class="range-info">
        <div class="info-row">
          <sapn class="info-name">管理 IP</sapn
          ><span class="info-value">192.168.61.171</span>
        </div>
        <div class="info-row">
          <sapn class="info-name">遮罩</sapn
          ><span class="info-value">255.255.255.0</span>
        </div>
        <div class="info-row">
          <sapn class="info-name">起始 IP</sapn
          ><span class="info-value">192.168.61.1</span>
        </div>
        <div class="info-row">
          <sapn class="info-name">結束 IP</sapn
          ><span class="info-value">192.168.61.254</span>
        </div>
        <div class="info-row">
          <sapn class="info-name">閘道</sapn
          ><span class="info-value">192.168.61.254</span>
        </div>
      </div>
      <div class="range-rule">
        <div class="rule-title-row">
          <span>DHCP Pool</span>
          <SvgIcon icon="plus" />
        </div>
        <div class="rule-item">
          <div class="operation-btn is-active">
            <SvgIcon icon="dhcp-service" />
          </div>
          <div class="operation-btn">
            <SvgIcon icon="lock" />
          </div>
          <div class="rule-detail">
            192.168.61.1 - 192.168.61.5
            <SvgIcon icon="cross" class="cross-btn" />
          </div>
        </div>
        <div class="rule-item">
          <div class="operation-btn">
            <SvgIcon icon="dhcp-service" />
          </div>
          <div class="operation-btn">
            <SvgIcon icon="lock" />
          </div>
          <div class="rule-detail">
            192.168.61.200 - 192.168.61.250
            <SvgIcon icon="cross" class="cross-btn" />
          </div>
        </div>
        <div class="rule-item">
          <div class="operation-btn is-disabled">
            <SvgIcon icon="dhcp-service" />
          </div>
          <div class="operation-btn is-disabled">
            <SvgIcon icon="lock" />
          </div>
          <div class="rule-detail is-danger">
            192.168.61.12 - 192.168.61.12
            <SvgIcon icon="cross" class="cross-btn" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
