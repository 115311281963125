<template>
  <div class="range-block">
    <div class="range-control">
      <SvgIcon icon="edit" /><SvgIcon icon="garbage" />
    </div>
    <div class="range-title">
      <SvgIcon icon="range" />
      2001:b030:1228:1002::
    </div>
    <div class="range-content">
      <div class="range-info">
        <div class="info-row">
          <sapn class="info-name">管理 IP</sapn
          ><span class="info-value">2001:b030:1328:1061::61:171</span>
        </div>
        <div class="info-row">
          <sapn class="info-name">Prefix</sapn
          ><span class="info-value">2001:b030:1328:1061:: /64</span>
        </div>
        <div class="info-row">
          <sapn class="info-name">閘道</sapn
          ><span class="info-value">2001:b030:1328:1061::61:254</span>
        </div>
      </div>
      <div class="range-rule">
        <div class="rule-title-row">
          <div>
            <span>派發規則</span>
            <span class="ml-3">對應 IPv4 尾兩碼</span>
          </div>
          <SvgIcon icon="plus" />
        </div>
      </div>
      <div class="hr-divider"></div>
      <div class="range-rule">
        <div class="rule-title-row">
          <span>DHCP Pool</span>
          <SvgIcon icon="plus" />
        </div>
        <div class="rule-item">
          <div class="operation-btn is-active">
            <SvgIcon icon="dhcp-service" />
          </div>
          <div class="operation-btn">
            <SvgIcon icon="lock" />
          </div>
          <div class="rule-detail">
            2001:b030:1228:1002:0:0:0:1
            <SvgIcon icon="cross" class="cross-btn" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
